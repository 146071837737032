<template>
  <div class="rate-mask" v-if="visible">
    <div class="rate-card">
      <!-- 期待您的好评 -->
      <div class="title">
        {{ $t("course.looking_forward_to_your_positive_comments") }}
      </div>
      <!-- 您的鼓励能让我们做得更好 -->
      <div class="subtitle">
        {{ $t("course.your_encouragement_can_make_us_do_better") }}
      </div>
      <div class="star">
        <a-rate
          v-model:value="star"
          :disabled="starDisabled"
          style="color: #f5961b; font-size: 34px"
        />
      </div>
      <div class="suggestion">
        <p>{{ $t("course.course_recommendations") }}：</p>
        <a-textarea
          v-model:value="suggestion"
          :maxlength="200"
          :placeholder="$t('course.course_recommendations')"
          :showCount="!suggestionDisabled"
          :rows="6"
          :disabled="suggestionDisabled"
        />
      </div>
      <div class="btn" v-if="!starDisabled || !suggestionDisabled">
        <a-button type="primary" @click="submit"
          :disabled="!star">{{
          $t("CM_Submit")
        }}</a-button>
      </div>
      <div class="close" @click="cancel">
        <CloseCircleOutlined />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const emits = defineEmits(["on-submit"]);
const { t: $t } = useI18n();

const visible = ref(false);
const star = ref(0);
const starDisabled = ref(false);
const suggestion = ref("");
const suggestionDisabled = ref(false);

function show(data) {
  star.value = data.myStar;
  starDisabled.value = data.myStar > 0;
  suggestion.value = data.suggestion;
  suggestionDisabled.value = data.suggestion != "";
  visible.value = true;
  document.querySelector("body").style.overflow = "hidden";
}
function cancel() {
  visible.value = false;
  document.querySelector("body").style.overflow = "";
}
function submit() {
  if (star.value) {
    emits("on-submit", {
      star: star.value,
      suggestion: suggestion.value,
    });
    cancel();
  }
}
defineExpose({
  show,
  cancel,
});
</script>
<style lang="less" scoped>
.rate-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.rate-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 476px;
  padding: 24px;
  border-radius: 8px;
  text-align: center;
  background: #fff url("~@/assets/image/course/star-bg.png") no-repeat top left;
  background-size: 100%;
  .title {
    margin-top: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .subtitle {
    margin: 10px 0;
    font-size: 12px;
    color: #999;
  }
  ::v-deep(.ant-rate-star:not(:last-child)) {
    margin-right: 18px;
  }
  .suggestion {
    padding-top: 24px;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 12px;
      text-align: left;
    }
  }
  .btn {
    margin-top: 15px;
  }
  .close {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: var(--theme);
    }
  }
}
</style>