<template>
  <!-- 实操作业 -->
  <a-modal v-model:visible="visible" :width="800" centered destroyOnClose :maskClosable="false" :keyboard="false"
    :title="$t('Sys_Homework')" :bodyStyle="{ padding: '10px 20px' }" @cancel="modalCancel">
    <a-spin :spinning="modalLoading">
      <h3>{{ homework.title }}</h3>
      <h5 class="border-title">{{ $t("homework.work_requirements") }}</h5>
      <!-- 作业要求 -->
      <div class="intro" v-if="homework.introList">
        <div class="intro-item" v-for="(item, i) in homework.introList" :key="i">{{ item }}</div>
      </div>
      <div class="intro" v-else-if="homework.intro">
        <div class="intro-item">{{ homework.intro }}</div>
      </div>
      <div class="attachments">
        <div class="attachments-item" v-for="item in homework.attachments || []" :key="item.documentId">
          <p class="attachments-item-p" @click="previewDoc(item)">
            <template v-if="item.secretData">
              {{ htsySecret[item.secretData] }}
            </template>
            {{
              item.fileName +
              item.filePath.substr(item.filePath.lastIndexOf("."))
            }}
          </p>
          <DownloadOutlined class="down" @click="fileDownload(item.sourceFilePath, item.fileName)" />
        </div>
      </div>
      <!-- 评估表评估学员不用提交作业 -->
      <template v-if="homework.completeCondition !== 3">
        <h5 class="border-title">{{ $t("homework.homework_content") }}</h5>
        <!-- 作业内容 -->
        <div class="steps" v-if="myHomework.status == 4 ||
          myHomework.status == 0 ||
          myHomework.status == 3
          ">
          <div class="steps-item" v-for="(item, index) in mySteps" :key="index">
            <div class="steps-item-head">
              <h6>{{ $t("homework.step", [index + 1]) }}</h6>
              <!-- 第 步 -->
              <div class="ctrl">
                <div class="ctrl-left">
                  <template v-if="!item.editing">
                    <span class="span">{{
                      $t("homework.attachment", [item.files.length])
                    }}</span>
                    <!-- 个附件 -->
                    <span class="span">{{ $t("Pub_Tab_Time") }}：{{
                      dateFormat(item.lastTime)
                    }}</span>
                    <!-- 时间： -->
                    <a-tooltip>
                      <template #title>{{ $t("BN_Comm_Edit") }}</template>
                      <!-- 编辑 -->
                      <EditOutlined class="ctrl-icon edit" @click="edit(index)" />
                    </a-tooltip>
                  </template>
                  <a-tooltip v-else>
                    <template #title>{{ $t("CM_Save") }}</template>
                    <!-- 保存 -->
                    <SaveOutlined class="ctrl-icon edit" @click="save(index)" />
                  </a-tooltip>
                </div>
                <!-- 确定要删除该步骤吗？ -->
                <a-popconfirm :title="$t('homework.sure_delete_step')" @confirm="del(index)">
                  <a-tooltip v-model:visible="item.delTips">
                    <template #title>{{ $t("CM_Delete") }}</template>
                    <!-- 删除 -->
                    <CloseOutlined class="ctrl-icon del" @click="item.delTips = false" />
                  </a-tooltip>
                </a-popconfirm>
              </div>
            </div>
            <div class="steps-item-content">
              <a-textarea v-if="item.editing" style="margin: 8px 0 10px" :rows="4" v-model:value="item.content"
                :placeholder="$t('homework.optional')" />
              <!-- 选填）请填写作业步骤内容，提交长内容时，建议先使用客户端软件编辑好，再粘贴到这里。 -->
              <div class="text" v-else-if="item.content">{{ item.content }}</div>
            </div>
            <div class="steps-item-files" v-if="item.editing">
              <div class="up-ctrl">
                <a-button @click="handleUpload(index)">
                  <UploadOutlined />
                  {{ $t("LB_UploadingAttachments") }}
                </a-button>
                <!-- 上传附件 -->
                <p class="explain">
                  {{ $t("upload.accept", [5, "200M"]) }}
                </p>
                <!-- 支持图片、文档、音频、视频，最多可上传5个附件，单个文件不超过200M -->
              </div>
              <ul class="upload-list">
                <li class="file-item" v-for="(file, iFile) in item.files" :key="iFile">
                  <PaperClipOutlined />
                  <span class="file-item-name">
                    <template v-if="file.secretData">
                      {{ htsySecret[file.secretData] }}
                    </template>
                    {{ file.name }}
                  </span>
                  <DeleteOutlined class="del" @click="removeFile(index, iFile)" />
                </li>
              </ul>
            </div>
          </div>
          <div class="steps-add">
            <a-button type="dashed" block @click="addStep">
              <PlusOutlined /> {{ $t("homework.add_steps") }}
              <!-- 添加步骤 -->
            </a-button>
          </div>
        </div>
        <div class="steps" v-else>
          <div class="steps-item" v-for="(item, index) in mySteps" :key="index">
            <div class="steps-item-head">
              <h6>{{ $t("homework.step", [index + 1]) }}</h6>
              <!-- 第 步 -->
            </div>
            <div class="steps-item-content" v-if="item.content">
              {{ item.content }}
            </div>
            <div class="attachments" v-if="item.attachments">
              <div class="attachments-item" v-for="(file, fileIndex) in item.attachments || []" :key="fileIndex">
                <p>
                  <template v-if="file.secretData">
                    {{ htsySecret[file.secretData] }}
                  </template>
                  {{
                    file.fileName +
                    file.filePath.substr(file.filePath.lastIndexOf("."))
                  }}
                </p>
                <DownloadOutlined class="down" @click="fileDownload(file.sourceFilePath, file.fileName)" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="homework.completeCondition == 2">
        <h5 class="border-title" v-if="myHomework.status != 4 || myHomework.status != 3" v-show="flows.length">
          {{ $t("Pub_Lab_MarkingStatus") }}
          <!-- 批阅状态 -->
        </h5>
        <div class="check" v-if="myHomework.status != 4 || myHomework.status != 3" v-show="flows.length">
          <div class="check-item" :class="{ green: item.status == 1, red: item.status == 3 }"
            v-for="(item, index) in flows" :key="index">
            <p>
              <span>{{ $t("Pub_Lab_MarkingStatus") }}：</span>
              <!-- 批阅状态 -->
              <i class="status">{{ statusEnum[item.status] }}</i>
            </p>
            <template v-if="item.status != 2">
              <p>
                <span>{{ $t("homework.review_time") }}：</span>
                <!-- 批阅时间： -->
                {{ dateFormat(item.createTime) }}
              </p>
              <p>
                <span>{{ $t("homework.reviewer") }}：</span>
                <!-- 批阅人 -->
                {{ item.realName }}
              </p>
              <p v-if="homework.maxScore > 0">
                <span>
                  {{ $t("LB_Favorite_Score") }}：
                  <!-- 评分 -->
                </span>
                {{ item.score }}
              </p>
              <p>
                <span>
                  {{ $t("homework.comments") }}：
                  <!-- 评语 -->
                </span>
                {{ item.remark }}
              </p>
            </template>
          </div>
        </div>
      </template>
      <!-- 作业评估 -->
      <template v-if="homework.completeCondition == 3 && homework.taskquestionList.length">
        <h5 class="border-title">{{ $t("homework.valuation") }}</h5>
        <div class="question">
          <div class="question-item" v-for="(item, i) in homework.taskquestionList" :key="i">
            <div class="name">
              <OpenData type="userName" :openid="item.realName" />
            </div>
            <!-- 未评估 / 查看评估 -->
            <div class="btn view" v-if="item.taskaskid" @click="onView(item)">{{ $t('homework.ViewEvaluation') }}</div>
            <div class="btn" v-else>{{ $t('homework.NotEvaluated') }}</div>
          </div>
        </div>
        <!-- 平均分 -->
        <div class="average" v-if="average.show">
          {{ $t('homework.AverageScore') }}:
          <span>{{ average.num }}</span>
          {{ $t('exam.score') }}
        </div>
      </template>
    </a-spin>
    <template #footer>
      <template v-if="(myHomework.status == 4 ||
          myHomework.status == 3 ||
          myHomework.status == 0) &&
        homework.completeCondition !== 3
        ">
        <a-button key="back" @click="modalCancel">{{
          $t("CM_Cancel")
        }}</a-button>
        <!-- 取消 -->
        <a-popconfirm :title="$t('sure_submit')" @confirm="modalOk">
          <!-- 确定提交吗？ -->
          <a-button key="submit" type="primary" :loading="modalLoading">
            {{ $t("CM_Submit") }}
            <!-- 提交 -->
          </a-button>
        </a-popconfirm>
      </template>
      <a-button v-else key="submit" type="primary" @click="modalCancel">
        {{ $t("CM_Confirm") }}
        <!-- 确定 -->
      </a-button>
    </template>
  </a-modal>
  <!-- 资料查看 -->
  <docPreview :isVisible="previewFile.visible" :documentId="previewFile.documentId" :fileStatus="previewFile.fileStatus"
    :fileType="previewFile.fileType" :filePath="previewFile.filePath" :fileName="previewFile.fileName"
    :fileHome="previewFile.fileHome" @previewEnd="(e) => {
        previewFile.visible = e;
      }
      " />
  <secretFile ref="secretFileRef" :config="{
    action: 'study/upload/file',
    accept: $t('upload.accept', [5, '200M']),
    maxSize: 200,
    limit: 5,
  }" @ok="upEnd" />
  <!-- 支持图片、文档、音频、视频，最多可上传5个附件，单个文件不超过200M -->
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";
import { uploadUrl, getHeader, dateFormat, fileDownload } from "@/utils/tools";
import {
  homeworkDetail,
  homeworkSave,
  homeworkDel,
  homeworkSubmit,
} from "@/api/homework";
import secretFile from "@/components/secretFile.vue";
import { htsySecret } from "@/utils/business";
import docPreview from "@/components/preview/index.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "homework",
  components: {
    secretFile,
    docPreview,
    OpenData,
  },
  emits: ["updata:homework"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      modalLoading: false,
      taskId: 0,
      detailId: 0,
      currentStep: 0,
      homework: {},
      myHomework: {
        status: 0,
      },
      mySteps: [],
      flows: [],
      courseApi: "",
      params: {}, // 请求参数
      previewFile: {
        visible: false,
        documentId: 0,
        fileStatus: 0,
        fileType: 0,
        filePath: "",
        fileName: "",
        fileHome: "",
      },
      // 平均分
      average: {
        show: false,
        num: 0,
      },
    });

    const openModal = (data) => {
      state.visible = true;
      state.modalLoading = true;
      state.average.show = false;
      if (data.courseId) {
        state.courseApi = "course/";
        state.params = {
          ...data,
        };
      } else {
        state.courseApi = "";
        state.params = {
          taskId: data.id,
          detailId: data.did,
        };
      }
      homeworkDetail(state.params, state.courseApi).then((res) => {
        state.homework = res.data.homework;
        state.myHomework = res.data.myHomework;
        state.flows = res.data.flows || [];
        state.mySteps = res.data.mySteps || [
          {
            content: "",
            attachments: [],
            files: [],
            lastTime: "",
            editing: true,
          },
        ];
        if (state.myHomework.status != 0) {
          state.mySteps.forEach((item) => {
            item.editing = false;
            item.attachments = item.attachments || [];
            item.files = item.attachments.map((file) => {
              return {
                name:
                  file.fileName +
                  file.filePath.substr(file.filePath.lastIndexOf(".")),
                url: file.filePath,
                status: "done",
                secretData: file.secretData,
                response: {
                  data: {
                    name: file.fileName,
                    file: file.filePath,
                    fileType: file.fileType,
                    fileId: file.fileId || 0,
                  },
                },
              };
            });
          });
        }
        state.currentStep = state.mySteps.length - 1;
        computedAverage(state.homework.taskquestionList);
        state.modalLoading = false;
      });
    };

    const statusEnum = {
      // 0: "新建",
      1: $t("CM_Pass"), // 通过
      2: $t("CM_Submit"), // 提交
      3: $t("XB_Unthread"), // 不通过
      // 4: "保存",
    };

    const secretFileRef = ref(null);
    const uploadIndex = ref(0);
    const handleUpload = (index) => {
      secretFileRef.value.show();
      uploadIndex.value = index;
    };
    const upEnd = ({ secret, file }) => {
      // state.memberStudy.files.push(...file);
      state.mySteps[uploadIndex.value].files.push(...file);
    };
    const removeFile = (index, iFile) => {
      state.mySteps[index].files.splice(iFile, 1);
    };

    const del = (index) => {
      if (
        !state.mySteps[state.currentStep].stepId &&
        state.mySteps.length > 1
      ) {
        proxy.$message.success($t("CM_SuccessfullyDelete"));
        // 删除成功
        state.mySteps.splice(index, 1);
        state.currentStep = 0;
      } else if (
        state.mySteps[state.currentStep].editing &&
        state.mySteps[state.currentStep].stepId
      ) {
        proxy.$message.warning($t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else if (state.mySteps.length <= 1) {
        proxy.$message.warning($t("homework.keep_one_step"));
        // 至少保留一个步骤
      } else {
        homeworkDel(
          {
            ...state.params,
            stepId: state.mySteps[index].stepId,
          },
          state.courseApi
        ).then((res) => {
          proxy.$message.success($t("CM_SuccessfullyDelete"));
          // 删除成功
          state.mySteps.splice(index, 1);
          state.currentStep = 0;
        });
      }
    };

    const edit = (index) => {
      if (state.mySteps[state.currentStep].editing) {
        proxy.$message.warning($t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else {
        state.mySteps[index].editing = true;
        state.currentStep = index;
      }
    };

    const save = (index) => {
      let item = state.mySteps[index];
      if (item.files.length || item.content.trim() !== "") {
        item.attachments = item.files.map((file) => {
          if (file.status == "done") {
            return {
              fileName: file.response.data.name,
              filePath: file.response.data.file,
              fileType: file.response.data.fileType,
              name: file.response.data.name,
              file: file.response.data.exist
                ? file.response.data.md5
                : file.response.data.file,
              secretData: file.response.data.secretData,
              fileId: file.response.data.fileId,
            };
          }
        });
        if (item.attachments.length > 5) {
          proxy.$message.warning($t("homework.upload_attachment_count", [5]));
          // 最多可上传5个附件
        } else {
          homeworkSave(
            {
              ...state.params,
              stepId: item.stepId || 0,
              content: item.content,
              attachments: item.attachments,
            },
            state.courseApi
          ).then((res) => {
            item.lastTime = Date.parse(new Date()) / 1000;
            item.editing = false;
            item.stepId = res.data;
            if (res.ext) {
              item.files = res.ext.map((file) => {
                return {
                  name:
                    file.fileName +
                    file.filePath.substr(file.filePath.lastIndexOf(".")),
                  url: file.filePath,
                  status: "done",
                  secretData: file.secretData,
                  response: {
                    data: {
                      name: file.fileName,
                      file: file.filePath,
                      fileType: file.fileType,
                      fileId: file.fileId || 0,
                    },
                  },
                };
              });
            }
            proxy.$message.success($t("Pub_Msg_SaveSuccess"));
            // 保存成功
            emit("updata:homework", "save");
          });
        }
      } else {
        proxy.$message.warning($t("homework.save_tip"));
        // 请填写内容或上传附件
      }
    };

    const addStep = () => {
      if (state.mySteps[state.currentStep].editing) {
        proxy.$message.warning($t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else {
        state.mySteps.push({
          content: "",
          files: [],
          lastTime: "",
          editing: true,
        });
        state.currentStep = state.mySteps.length - 1;
      }
    };

    const modalOk = () => {
      if (state.mySteps[state.currentStep].editing) {
        proxy.$message.warning($t("homework.save_current_step"));
        // 请先保存当前编辑的步骤
      } else {
        state.modalLoading = true;
        homeworkSubmit(
          {
            ...state.params,
            steps: state.mySteps,
          },
          state.courseApi
        ).then((res) => {
          proxy.$message.success($t("LB_Teacher_SubSuccess"));
          // 提交成功
          emit("updata:homework", "submit");
          state.modalLoading = false;
          modalCancel();
        });
      }
    };

    const modalCancel = () => {
      state.visible = false;
    };

    const previewDoc = (item) => {
      state.previewFile.visible = true;
      state.previewFile.fileStatus = item.status;
      state.previewFile.documentId = item.documentId || 0;
      state.previewFile.fileType = item.fileType;
      state.previewFile.filePath = item.filePath;
      state.previewFile.fileName = item.fileName || "";
      state.previewFile.fileHome = item.home || "";
    };

    const computedAverage = (list) => {
      if (!list || !list.length) return;
      let indexTotal = 0;
      let average = 0;
      list.forEach(item => {
        if (item.taskaskid) {
          indexTotal += 1;
          average += item.totalscore;
        }
      });
      if (indexTotal === list.length) {
        state.average.num = (average / indexTotal).toFixed(2);
        state.average.show = true;
      }
    };
    const onView = (item) => {
      router.push({
        path: '/questionnaire/homework',
        query: {
          id: state.params.taskId,
          did: state.params.detailId,
          questionnaireId: state.homework.evaluationId,
          taskaskId: item.taskaskid,
          disabled: 1,
        }
      })
    };

    return {
      uploadUrl,
      getHeader,
      dateFormat,
      ...toRefs(state),
      openModal,
      fileDownload,
      statusEnum,
      del,
      edit,
      save,
      addStep,
      modalOk,
      modalCancel,
      secretFileRef,
      handleUpload,
      upEnd,
      removeFile,
      htsySecret,
      previewDoc,
      onView,
    };
  },
};
</script>

<style lang="less" scoped>
.border-title {
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 14px;
    background-color: @color-theme;
    position: absolute;
    left: 0;
    top: 3px;
  }
}

.attachments {
  margin-top: 5px;

  &-item {
    padding: 0 10px;
    background-color: #f1f1f1;
    margin-bottom: 1px;
    .mixinFlex(space-between; center);

    p {
      margin: 0;
      width: 90%;
      .mixinEllipsis(30px);
    }

    &-p {
      cursor: pointer;

      &:hover {
        color: @color-theme;
      }
    }

    .down {
      &:hover {
        color: @color-theme;
      }
    }
  }
}

.question {
  margin-top: 5px;

  &-item {
    padding: 0 10px;
    background-color: #f1f1f1;
    margin-bottom: 1px;
    .mixinFlex(space-between; center);

    .name {
      width: 90%;
      .mixinEllipsis(30px);
    }

    .btn {
      color: var(--theme);
    }

    .view {
      cursor: pointer;
    }
  }
}

.average {
  margin-top: 10px;

  span {
    color: var(--theme);
  }
}

.steps {
  margin-top: 10px;

  &-item {
    padding: 10px;
    border: 1px dashed #d9d9d9;
    margin-bottom: 10px;

    &-head {
      .mixinFlex(space-between; center);
      line-height: 30px;

      h6 {
        margin: 0;
      }

      .ctrl {
        .mixinFlex(flex-start; center);

        .span {
          margin-left: 16px;
        }

        &-icon {
          margin-left: 14px;
          cursor: pointer;
          font-size: 16px;

          &.edit {
            color: @color-theme;
          }

          &.del {
            color: red;
          }
        }
      }
    }

    &-content {
      .text {
        padding: 5px;
        margin: 8px 0 10px;
        background-color: #f1f1f1;
      }
    }

    &-files {
      .up-ctrl {
        .mixinFlex(flex-start; center);

        .explain {
          padding-left: 20px;
          margin: 0;
          color: #666;
        }
      }
    }
  }
}

.check {
  border-left: 1px solid @color-theme;
  margin-left: 20px;

  &-item {
    padding-left: 10px;
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .status {
      font-style: normal;
    }

    &::before {
      content: "";
      background-color: @color-theme;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: -3px;
    }

    &.green {
      &::before {
        background-color: green;
      }

      .status {
        color: green;
      }
    }

    &.red {
      &::before {
        background-color: red;
      }

      .status {
        color: red;
      }
    }

    p {
      margin-bottom: 2px;
      .mixinFlex(flex-start);

      span {
        width: 75px;
      }
    }
  }
}

.upload-list {
  .file-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f5f5f5;

    &-name {
      flex: 1;
      margin: 0 10px;
    }
  }
}
</style>
