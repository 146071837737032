<template>
  <a-modal
    v-model:visible="visible"
    title=""
    :width="890"
    :centered="true"
    :closable="false"
    :keyboard="false"
    :maskClosable="false"
    :bodyStyle="{ padding: 0 }"
    :footer="null"
  >
    <div class="questions" v-if="visible">
      <div class="questions-head">
        <span class="type">{{
          questionTypeEnum[currentQue.questionType]
        }}</span>
        <span class="number"
          ><i>{{ queIndex + 1 }}</i
          >/{{ questions.length }}</span
        >
      </div>
      <div class="questions-body">
        <div :class="['questions-item', { result: currentQue.isOver }]">
          <p class="title">{{ currentQue.title }}</p>
          <template v-if="currentQue.titimgs && currentQue.titimgs.length">
            <exam-media :mediaList="currentQue.titimgs" />
          </template>
          <div v-if="currentQue.questionType === 2">
            <a-checkbox-group
              v-model:value="currentQue.userAnswer"
              :disabled="currentQue.isOver"
            >
              <a-checkbox
                class="opt-checkbox"
                :value="opt.id"
                v-for="(opt, optIndex) in currentQue.options"
                :key="opt.id"
              >
                <div
                  :class="[
                    'opt-item',
                    { right: currentQue.isOver && opt.isRight },
                    { error: currentQue.isOver && !opt.isRight },
                  ]"
                >
                  <div class="letter">{{ letterOpts[optIndex] }}</div>
                  <div class="info">
                    <span class="text">{{ opt.title || '&nbsp;&nbsp;' }}</span>
                    <exam-media
                      v-if="opt.img"
                      type="option"
                      :mediaList="[opt.img]"
                    />
                  </div>
                </div>
              </a-checkbox>
            </a-checkbox-group>
          </div>
          <div v-else>
            <a-radio-group
              v-model:value="currentQue.userAnswer"
              :disabled="currentQue.isOver"
            >
              <a-radio
                class="opt-radio"
                v-model:value="opt.id"
                v-for="(opt, optIndex) in currentQue.options"
                :key="opt.id"
              >
                <div
                  :class="[
                    'opt-item',
                    { right: currentQue.isOver && opt.isRight },
                    { error: currentQue.isOver && !opt.isRight },
                  ]"
                >
                  <div class="letter">{{ letterOpts[optIndex] }}</div>
                  <div class="info">
                    <span class="text">{{ opt.title || '&nbsp;&nbsp;' }}</span>
                    <exam-media
                      v-if="opt.img"
                      type="option"
                      :mediaList="[opt.img]"
                    />
                  </div>
                </div>
              </a-radio>
            </a-radio-group>
          </div>
          <div class="result" v-if="currentQue.isOver">
            <div class="answers">
              <div class="answers-item">
                {{ $t("practice.your_choice") }}：
                <template
                  v-for="(opt, optIndex) in currentQue.options"
                  :key="optIndex"
                >
                  <span
                    v-if="
                      currentQue.questionType == 2
                        ? currentQue.userAnswer.includes(opt.id)
                        : currentQue.userAnswer == opt.id
                    "
                  >
                    {{ letterOpts[optIndex] }}
                  </span>
                </template>
              </div>
              <div class="answers-item">
                {{ $t("Lab_Lesson_RightKey") }}：
                <template
                  v-for="(opt, optIndex) in currentQue.options"
                  :key="optIndex"
                >
                  <span v-if="opt.isRight">
                    {{ letterOpts[optIndex] }}
                  </span>
                </template>
              </div>
            </div>
            <div
              class="analysis"
              v-if="
                currentQue.analysis ||
                (currentQue.analysisimgs && currentQue.analysisimgs.length)
              "
            >
              <span class="analysis-label">{{
                $t("LB_Exam_QuestionAnalysis")
              }}</span
              >：{{ currentQue.analysis }}
            </div>
            <template
              v-if="currentQue.analysisimgs && currentQue.analysisimgs.length"
            >
              <exam-media :mediaList="currentQue.analysisimgs" />
            </template>
          </div>
        </div>
      </div>
      <div class="questions-foot">
        <template v-if="currentQue.isOver">
          <a-button
            v-if="queIndex == questions.length - 1"
            type="primary"
            @click="close"
            >{{ $t("Pub_Close") }}</a-button
          >
          <a-button v-else type="primary" @click="next">{{
            $t("LB_Exam_NestQuestion")
          }}</a-button>
        </template>
        <a-button
          v-else
          type="primary"
          @click="confirm"
          :disabled="
            currentQue.questionType == 2
              ? currentQue.userAnswer.length <= 1
              : currentQue.userAnswer === ''
          "
          >{{ $t("CM_OK") }}</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from "vue";
import { letterOpts, questionTypeEnum } from "@/utils/business";
import examMedia from "@/views/exam/exam-media.vue";
export default {
  name: "nodeQues",
  components: {
    examMedia,
  },
  setup(_, { emit }) {
    const state = reactive({
      visible: false,
      questions: [],
      queIndex: 0,
      currentQue: {},
    });

    // 数组打乱顺序
    const shuffle = (array) => {
      let m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    const renderQues = (list) => {
      list.forEach((item) => {
        if (item.questionType === 1 || item.questionType === 3) {
          item.userAnswer = "";
        } else if (item.questionType === 2) {
          item.userAnswer = [];
        }
        if (item.optionRandom == 1) {
          item.options = shuffle(item.options);
        }
      });
    };

    const next = () => {
      state.queIndex++;
      state.currentQue = state.questions[state.queIndex];
    };

    const confirm = () => {
      state.currentQue.isOver = true;
      let sound = new Audio();
      if (state.currentQue.questionType == 2) {
        if (
          state.currentQue.strAnswer ==
          state.currentQue.userAnswer.sort().join(",")
        ) {
          sound.src = require("@/assets/mp3/right.mp3");
        } else {
          sound.src = require("@/assets/mp3/error.mp3");
        }
      } else {
        if (state.currentQue.strAnswer == state.currentQue.userAnswer) {
          sound.src = require("@/assets/mp3/right.mp3");
        } else {
          sound.src = require("@/assets/mp3/error.mp3");
        }
      }
      sound.play();
    };

    const open = (data) => {
      state.questions = JSON.parse(JSON.stringify(data));
      renderQues(state.questions);
      state.visible = true;
      state.queIndex = 0;
      state.currentQue = state.questions[0];
    };

    const close = () => {
      state.visible = false;
      emit("close");
    };

    return {
      letterOpts,
      questionTypeEnum,
      ...toRefs(state),
      next,
      confirm,
      open,
      close,
    };
  },
};
</script>

<style lang="less" scoped>
.questions {
  &-head {
    padding: 20px 24px;
    border-bottom: 1px solid #f0f0f0;
    .mixinFlex(space-between; center);
    font-size: 16px;
    line-height: 16px;
    .type {
      padding-left: 14px;
      border-left: 4px solid var(--theme);
    }
    .number {
      color: #999;
      letter-spacing: 1px;
      i {
        color: var(--theme);
      }
    }
  }
  &-body {
    height: 500px;
    overflow-y: auto;
  }
  &-item {
    padding: 16px 24px;
    .title {
      font-size: 18px;
      line-height: 1.7;
      color: #202020;
      margin-bottom: 16px;
    }
    .result {
      color: #202020;
      .answers {
        .mixinFlex(space-between; center);
        &-item {
          width: 50%;
          font-size: 14px;
          span {
            padding-right: 4px;
            color: var(--theme);
          }
        }
      }
      .analysis {
        margin-top: 8px;
        &-label {
          font-weight: bold;
        }
      }
    }
    .opt-checkbox,
    .opt-radio {
      .mixinFlex(flex-start; center);
      white-space: normal !important;
      ::v-deep(.ant-checkbox),
      ::v-deep(.ant-radio) {
        display: none;
        width: 0px;
        height: 0px;
        overflow: hidden;
      }
      ::v-deep(span.ant-radio + *),
      ::v-deep(.ant-checkbox + span) {
        padding-right: 0;
        padding-left: 0;
      }
      .opt-item {
        .mixinFlex(flex-start; flex-start);
        font-size: 16px;
        margin-bottom: 16px;
        border-radius: 4px;
        background: rgba(250, 252, 254, 1);
        border: 1px solid rgba(235, 235, 235, 1);
        padding: 12px;
        // &:hover {
        //   border-color: #266fff;
        // }
        &.right {
          background-color: #eafeee;
          border-color: #2ec45a;
        }
        &.error {
          background-color: #feefef;
          border-color: #e82f2e;
        }
        ::v-deep(.opt-media-wrap) {
          margin-left: -40px;
        }
        .letter {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          .mixinFlex(center; center);
          margin-right: 12px;
          border: 1px solid #dddddd;
        }
        .info {
          width: calc(100% - 40px);
        }
        .text {
          color: #202020;
          line-height: 28px;
          max-width: calc(100% - 48px);
        }
      }
      &.ant-checkbox-wrapper-checked,
      &.ant-radio-wrapper-checked {
        .letter {
          border-color: #266fff;
          color: #fff;
          background-color: #266fff;
        }
        .text {
          color: #266fff;
        }
      }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  &-foot {
    text-align: center;
    padding: 12px 24px;
    border-top: 1px solid #f0f0f0;
  }
}
</style>
