<template>
  <a-modal
    :title="$t('course.ScoreDetails')"
    :width="982"
    :bodyStyle="{ padding: '16px' }"
    :centered="true"
    :visible="visible"
    :footer="false"
    @cancel="close"
  >
    <div class="table-wrap" style="margin-top: 0">
      <div class="table-content">
        <a-table
          size="middle"
          :rowKey="(record, index) => index"
          :scroll="{ x: 950, y: 400 }"
          :dataSource="dataList"
          :columns="columns"
          :pagination="false"
        >
          <template #tooltip="{ text }">
            <a-tooltip placement="topLeft" :title="text">
              {{ text }}
            </a-tooltip>
          </template>
          <template #trueOrFalse="{ text }">
            {{ text ? $t("course.right") : $t("course.wrong") }}
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
// import tdTooltip from '@/components/com/td/tooltip.vue';
export default defineComponent({
  name: "scoreDetails", // 实验成绩明细
  components: {
    // tdTooltip,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      visible: false,
      userName: "",
      dataList: [],
      columns: [
        {
          title: t("course.ModuleName"), // 模块名称
          dataIndex: "moduleFlag",
          slots: { customRender: "tooltip" },
          width: 180,
          align: "left",
          ellipsis: true,
        },
        {
          title: t("course.Stem"), // 题干
          dataIndex: "questionStem",
          slots: { customRender: "tooltip" },
          width: 180,
          align: "left",
          ellipsis: true,
        },
        {
          title: t("course.Score"), // 得分
          dataIndex: "score",
          width: 100,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.DidYouAnswerCorrectly"), // 是否答对
          dataIndex: "trueOrFalse",
          slots: { customRender: "trueOrFalse" },
          width: 120,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.StartTimeOfExperimentalSteps"), // 实验步骤开始时间
          dataIndex: "startTime",
          width: 180,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.EndTimeOfExperimentalSteps"), // 实验步骤结束时间
          dataIndex: "endTime",
          width: 180,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.ReasonableUseOfTimeForExperimentalSteps"), // 实验步骤合理用时（秒）
          dataIndex: "expectTime",
          width: 180,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.FullMarksForExperimentalSteps"), // 实验步骤满分
          dataIndex: "maxScore",
          width: 180,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.NumberOfExperimentalStepsAndOperations"), // 实验步骤操作次数
          dataIndex: "repeatCount",
          width: 180,
          align: "center",
          ellipsis: true,
        },
        {
          title: t("course.ExperimentalStepEvaluation"), // 实验步骤评价
          dataIndex: "evaluation",
          slots: { customRender: "tooltip" },
          width: 180,
          align: "left",
          ellipsis: true,
        },
        {
          title: t("course.InspectionPoint"), // 考察点
          dataIndex: "scoringModel",
          slots: { customRender: "tooltip" },
          width: 180,
          align: "left",
          ellipsis: true,
        },
        {
          title: t("course.Remarks"), // 备注
          dataIndex: "remarks",
          slots: { customRender: "tooltip" },
          width: 180,
          align: "left",
          ellipsis: true,
        },
      ],
    });

    const open = (data) => {
      state.userName = data.realName;
      state.dataList = JSON.parse(data.expScoreDetails || "[]");
      state.visible = true;
    };

    const close = () => {
      state.visible = false;
      state.userName = "";
      state.dataList = [];
    };

    return {
      ...toRefs(state),
      open,
      close,
    };
  },
});
</script>

<style lang="less" scoped>
// @import '~@/assets/style/common.less';
</style>
