<template>
  <a-modal
    v-model:visible="visible"
    :width="900"
    :title="$t('AddNotes')"
    centered
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <wangeditor
      v-if="note.courseId"
      :heigt="400"
      :editorValue="note.content"
      :exIncludes="exIncludesMenu"
      @getWangEditorValue="getWangEditorValue"
    />
  </a-modal>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance } from 'vue';
import wangeditor from '@/components/wangeditor/index.vue';
import { saveCourseNotes } from '@/api/course';
export default {
  name: "editNotes",
  components: {
    wangeditor,
  },
  setup(porps, { emit }) {
    const { proxy } = getCurrentInstance();
    const visible = ref(false);
    const state = reactive({
      note: {},
      exIncludesMenu: [
        "quote",
        "video",
        "table",
      ],
    });
    const show = (val) => {
      visible.value = true;
      state.note = val;
    };
    const handleCancel = () => {
      visible.value = false;
      state.note = {};
    };
    const handleOk = () => {
      if (!state.note.content) return proxy.$message.error(proxy.$t('ContentCannotBeEmpty'));
      const params = {
        courseID: state.note.courseId,
        content: state.note.content,
        noteId: state.note.noteId,
        title: state.note.title,
      };
      saveCourseNotes(params).then((res) => {
        if (res.ret === 0) {
          proxy.$message.success(proxy.$t('Pub_Msg_SaveSuccess'));
          handleCancel();
          emit("success");
        } else {
          proxy.$message.error(res.msg);
        }
      });
    };
    const getWangEditorValue = (val) => {
      state.note.content = val;
    };
    return {
      visible,
      ...toRefs(state),
      show,
      handleCancel,
      handleOk,
      getWangEditorValue,
    };
  },
};
</script>

<style lang="less" scoped>
</style>
