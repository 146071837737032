<template>
  <div class="comment-wrap">
    <div class="CommentGroup" v-if="!disabled">
      <a-textarea
        :rows="4"
        v-model:value="message"
        :placeholder="$t('LB_Comment_SaySomething')"
      />
      <!-- 说点什么吧 -->
      <a-button
        html-type="submit"
        :loading="submitting"
        type="primary"
        @click="handleSubmit(1)"
        :disabled="message.length <= 0"
        >{{ $t("LB_Doc_Comments") }}</a-button
      >
      <!-- 评论 -->
    </div>
    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="dataList"
    >
      <template #loadMore>
        <div
          :style="{
            textAlign: 'center',
            marginTop: '30px',
            height: '32px',
            lineHeight: '32px',
            color: '#BCBCBC',
          }"
        >
          <a-spin v-if="loading" />
          <div v-else-if="dataList.length <= 0"></div>
          <div v-else-if="finished">{{ $t("no_more") }}</div>
          <!-- 没有更多了 -->
          <a-button v-else @click="loadMore">{{ $t("load_more") }}</a-button>
          <!-- 加载更多 -->
        </div>
      </template>
      <template #renderItem="{ item, index }">
        <a-list-item>
          <a-list-item-meta>
            <template #title>
              <a-tag v-if="item.istop" color="#ff4d4f">{{
                $t("bbs.top")
              }}</a-tag>
              <OpenData type="userName" :openid="item.createUser" />
              <div
                class="comment-medal"
                v-if="item.mediaImage && companyInfo.menu.includes('medal')"
              >
                <div class="medal-name">
                  <img draggable="false" :src="item.mediaImage" alt="" />
                  {{ item.medalName }}
                </div>
              </div>
            </template>
            <template #avatar>
              <a-avatar :src="item.portrait" :size="45" />
            </template>
            <template #description>
              <div class="fontStyle" v-if="isShowDepart && item.departName">
                {{ item.departName }}
              </div>
              <div class="p content">{{ item.content }}</div>
              <div class="p">
                <span class="time">
                  <a-tooltip
                    :title="
                      moment(item.createTime * 1000).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )
                    "
                  >
                    <span>{{ timeFrom(item.createTime * 1000) }}</span>
                  </a-tooltip>
                  <span class="pl-icon" v-if="!disabled">
                    <a
                      href="javascript:;"
                      v-if="item.createUid == userInfo.userId"
                    >
                      <!-- 确定要删除该条评论吗 -->
                      <a-popconfirm
                        :title="$t('mine.sure_delete_comment')"
                        @confirm="del(item.commentId)"
                      >
                        <DeleteOutlined />
                      </a-popconfirm>
                    </a>
                    <a href="javascript:;" @click="showReplyComment(item)">
                      <MessageOutlined />
                    </a>
                    <a
                      href="javascript:;"
                      @click="like(item.commentId, index)"
                      :style="{ color: item.isLike ? '#ff9900' : '#999999' }"
                    >
                      <LikeOutlined /> {{ item.likes }}
                    </a>
                  </span>
                </span>
                <div class="CommentGroup" v-if="item.showComment">
                  <a-textarea
                    :rows="1"
                    v-model:value="replyMessage"
                    :placeholder="
                      $t('CM_Reply') +
                      (platformConfig.platform == 26 ||
                      platformConfig.platform == 31
                        ? ''
                        : item.createUser) +
                      '：'
                    "
                    ref="replyTextarea"
                  />
                  <a-button
                    html-type="submit"
                    :loading="replySubmitting"
                    type="primary"
                    @click="handleSubmit(2)"
                    :disabled="replyMessage.length <= 0"
                    >{{ $t("CM_Reply") }}</a-button
                  >
                  <!-- 回复 -->
                </div>
                <div
                  class="pl-reply"
                  v-if="item.replys && item.replys.length > 0"
                >
                  <CaretUpOutlined
                    style="
                      position: absolute;
                      top: -13px;
                      left: 15px;
                      font-size: 18px;
                      color: #f4f4f4;
                    "
                  />
                  <a-list-item-meta
                    v-for="(rItem, rIndex) in item.replys"
                    :key="rIndex"
                  >
                    <template #title>
                      <OpenData type="userName" :openid="rItem.createUser" />
                      <div
                        class="comment-medal"
                        v-if="
                          rItem.mediaImage && companyInfo.menu.includes('medal')
                        "
                      >
                        <div class="medal-name">
                          <img
                            draggable="false"
                            :src="rItem.mediaImage"
                            alt=""
                          />
                          {{ rItem.medalName }}
                        </div>
                      </div>
                    </template>
                    <template #avatar>
                      <a-avatar :src="rItem.portrait" :size="45" />
                    </template>
                    <template #description>
                      <div
                        class="fontStyle"
                        v-if="isShowDepart && rItem.createDepartName"
                      >
                        {{ rItem.createDepartName }}
                      </div>
                      <div class="p content">
                        {{ $t("CM_Reply") }}
                        <!-- 回复 -->
                        <span style="color: #202020; margin: 0 5px">
                          <OpenData
                            type="userName"
                            :openid="rItem.toUser"
                          /> </span
                        >：{{ rItem.content }}
                      </div>
                      <div class="p">
                        <span class="time">
                          <a-tooltip
                            :title="
                              moment(rItem.createTime * 1000).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            "
                          >
                            <span>{{ timeFrom(rItem.createTime * 1000) }}</span>
                          </a-tooltip>
                        </span>
                        <span class="pl-icon" v-if="!disabled">
                          <a
                            href="javascript:;"
                            v-if="rItem.createUid == userInfo.userId"
                          >
                            <!-- 确定要删除该条回复吗 -->
                            <a-popconfirm
                              :title="$t('mine.sure_delete_reply')"
                              @confirm="del(rItem.commentId)"
                            >
                              <DeleteOutlined />
                            </a-popconfirm>
                          </a>
                        </span>
                      </div>
                    </template>
                  </a-list-item-meta>
                </div>
              </div>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  docComments,
  docCommentSubmit,
  docCommentDel,
  docCommentLike,
} from "@/api/doc";
import {
  courseComments,
  courseCommentSubmit,
  courseCommentDel,
  courseCommentLike,
} from "@/api/course";
import {
  projectComments,
  projectCommentSubmit,
  projectCommentDel,
  projectCommentLike,
} from "@/api/project";
import {
  newsComments,
  newsCommentSubmit,
  newsCommentDel,
  newsCommentLike,
} from "@/api/news";
import {
  trainComments,
  trainCommentSubmit,
  trainCommentDel,
  trainCommentLike,
} from "@/api/train";
import { getCurrentInstance, reactive, toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import ls from "@/utils/local-storage";
import { timeFrom } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";

let getComment = [
  docComments,
  courseComments,
  projectComments,
  newsComments,
  trainComments,
];
let commentSubmit = [
  docCommentSubmit,
  courseCommentSubmit,
  projectCommentSubmit,
  newsCommentSubmit,
  trainCommentSubmit,
];
let delComment = [
  docCommentDel,
  courseCommentDel,
  projectCommentDel,
  newsCommentDel,
  trainCommentDel,
];
let likeComment = [
  docCommentLike,
  courseCommentLike,
  projectCommentLike,
  newsCommentLike,
  trainCommentLike,
];

export default {
  name: "myComment",
  components: {
    OpenData,
  },
  props: {
    CommentParams: {
      type: Object, // 类型检查  对象
    },
    ShowReply: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      commentParams: props.CommentParams,
      showReply: props.ShowReply,
      loading: true, // 是否处于加载状态，加载过程中不触发load事件
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      dataList: [],
      userInfo: ls.get("userInfo"),
      message: "",
      replyMessage: "",
      submitting: false,
      replySubmitting: false,
      commentId: 0,
      createUid: 0,
    });

    const companyInfo = computed(() => store.getters.companyInfo);
    const platformConfig = computed(() => store.getters.platformConfig);
    const isShowDepart = computed(
      () =>
        platformConfig.value.useComment == 1 &&
        platformConfig.value.commentDisplayDepart == 1
    );
    //上拉加载
    const loadMore = () => {
      state.loading = true;
      // 异步更新数据
      setTimeout(() => {
        getList();
      }, 100);
    };

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.commentParams.page = 1;
      state.loading = true;
      state.finished = false;
      getList();
    };

    const getList = () => {
      getComment[state.commentParams.type](state.commentParams).then((res) => {
        if (res.ret == 0) {
          state.dataList.push.apply(state.dataList, res.data.list);
          state.dataList.map((item) => {
            item.showComment = false;
          });
          state.commentParams.Total = res.data.totals;
          //每次拿到数据后页数加1
          state.commentParams.page += 1;
          // 加载状态结束
          state.loading = false;
          // 数据全部加载完成
          if (state.dataList.length >= res.data.totals) {
            state.finished = true;
          }
        } else {
          state.loading = false;
          // proxy.$message.error(res.Message);
          return false;
        }
      });
    };
    const replyTextarea = ref(null);
    const showReplyComment = (item) => {
      let isShow = item.showComment;
      state.dataList.map((item) => {
        item.showComment = false;
      });
      item.showComment = !isShow;
      // 输入框自动聚焦
      if (item.showComment) {
        setTimeout(() => {
          replyTextarea.value.focus();
        }, 100);
      }
      state.replyMessage = "";
      state.commentId = item.commentId;
      state.createUid = item.createUid;
    };
    // 发表评论  commentType 类型：1评论  2回复
    const handleSubmit = (commentType) => {
      let params = {};
      let msg = "";
      if (commentType == 1) {
        msg = $t("Pub_Msg_SuccessComment"); // 评论成功
        params = {
          commentId: 0,
          content: state.message,
          toUid: 0,
          type: state.commentParams.taskType || 0,
          resourceId: state.commentParams.resourceId,
        };
        state.submitting = true;
      } else {
        msg = $t("Pub_Lab_ReplySuccess"); // 回复成功
        params = {
          commentId: state.commentId,
          content: state.replyMessage,
          toUid: state.createUid,
          type: state.commentParams.taskType || 0,
          resourceId: state.commentParams.resourceId,
        };
        state.replySubmitting = true;
      }
      commentSubmit[state.commentParams.type](params).then((data) => {
        if (data.ret == 0) {
          // proxy.$message.success(msg);
          state.message = "";
          state.replyMessage = "";
          reloadList();
        }
        state.submitting = false;
        state.replySubmitting = false;
      });
    };

    const del = (id) => {
      let params = {
        id: id,
        commentId: id,
        resourceId: state.commentParams.resourceId,
        type: state.commentParams.type,
      };
      delComment[state.commentParams.type](params).then((res) => {
        if (res.ret == 0) {
          // proxy.$message.success("删除成功！");
          reloadList();
        }
      });
    };

    const like = (id, index) => {
      likeComment[state.commentParams.type]({
        id: id,
        type: state.commentParams.taskType || 0,
        resourceId: state.commentParams.resourceId,
      }).then((res) => {
        if (res.ret == 0) {
          if (!state.dataList[index].isLike) {
            // proxy.$message.success("点赞成功！");
            state.dataList[index].isLike = true;
            state.dataList[index].likes += 1;
          } else {
            // proxy.$message.success("取消点赞成功！");
            state.dataList[index].isLike = false;
            state.dataList[index].likes -= 1;
          }
        }
      });
    };

    setTimeout(() => {
      getList();
    }, 100);

    return {
      ...toRefs(state),
      companyInfo,
      platformConfig,
      moment,
      timeFrom,
      loadMore,
      showReplyComment,
      handleSubmit,
      del,
      like,
      replyTextarea,
      isShowDepart,
    };
  },
};
</script>

<style lang="less" scoped>
.learnUsers {
  font-size: 14px;
  font-weight: 600;
  color: #202020;
  padding-bottom: 20px;
}
.CommentGroup {
  text-align: right;
  textarea.ant-input {
    margin-bottom: 20px;
  }
}
.p {
  margin-bottom: 7px;
  .time {
    color: #bbbbbb;
  }
  .pl-icon {
    font-size: 15px;
    .fr();
    a {
      color: #999999;
      margin-left: 40px;
    }
  }
  textarea.ant-input {
    margin: 15px 0;
  }
}
.p:nth-last-child(1) {
  margin: 0;
}
.p.content {
  color: #666666;
}
.pl-reply {
  position: relative;
  padding: 20px 20px;
  background: #f4f4f4;
  margin-top: 12px;
  .ant-list-item-meta:not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e5e8ee;
  }
}
.ant-list-item {
  padding: 30px 0;
}
.fontStyle {
  color: #448bf7;
}
::v-deep(.ant-list-item-meta-avatar) {
  position: relative;
}
.comment-medal {
  padding-left: 13px;
  margin-left: 5px;
  .mixinFlex(flex-start, center);
  display: inline-flex;
  .medal-name {
    display: inline-block;
    min-width: 52px;
    height: 16px;
    background: linear-gradient(
      90deg,
      rgba(252, 106, 43, 1) 0%,
      rgba(222, 205, 58, 1) 100%
    );
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 0 8px 0 14px;
    border-radius: 0 8px 8px 0;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: -13px;
      top: -4px;
    }
  }
}
</style>
