<template>
  <!--  fail   :class="{ ok: loading ? true : hasFace }" -->
  <div
    class="face-main"
  >
    <video id="video" width="250" height="250" preload autoplay loop muted></video>
    <canvas id="canvas" width="250" height="250"></canvas>
    <div class="tip">{{ tip }}</div>
  </div>
  <div class="normal-face" v-show="normalFace"></div>
  <div class="pane-box">
    <div v-show="showAnimation" class="pane"></div>
  </div>
</template>
<script setup>
require("@/assets/js/tracking-min.js");
require("@/assets/js/face-min.js");
require("@/assets/js/dat.gui.min.js");
require("@/assets/js/stats.min.js");
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance, onUnmounted } from "vue";
import { detectionFace } from "@/api/user";

const props = defineProps({
  // 是否自动活体检测
  isTest: {
    type: Boolean,
    default: true,
  },
});
const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
let trackerTask = null;
let mediaStreamTrack = null;
let hasFace = ref(false);
let showAnimation = ref(false);
let isSaveImg = ref(true); // 是否保存照片
let faceImg = ref([]); // 人脸照片
let action = [$t('PleaseShakeYourHead'), $t('PleaseBlink'), $t('PleaseOpenYourMouth')] ;// ['请摇摇头', '请眨眨眼', '请张张嘴'];
let tip = ref('');
let faceBlob = ref([]);
let normalFace = ref(true);

function openCamera(isSample) {
  mediaStreamTrack = document.getElementById("video");
  let canvas = document.getElementById("canvas");
  let context = canvas.getContext("2d");

  let tracker = new window.tracking.ObjectTracker("face");
  tracker.setInitialScale(4);
  tracker.setStepSize(2);
  tracker.setEdgesDensity(0.1);

  trackerTask = window.tracking.track("#video", tracker, { camera: true });

  showAnimation.value = true;
  normalFace.value = false;
  tracker.on("track", function (event) {
    if (event.data.length == 1) {
      hasFace.value = true;
      // 人脸匹配
      if (!isSample && isSaveImg.value) {
        isSaveImg.value = false;
        tip.value = action[faceImg.value.length] || '';
        // 保存照片
        const base64 = getPhoto();
        faceImg.value.push(base64);
        if (faceImg.value.length === 3) {
          handleTest();
          return false;
        }
        setTimeout(() => {
          isSaveImg.value = faceImg.value.length < 3 ? true : false;
        }, 1500)
      }
    } else {
      hasFace.value = false;
    }
    // 采样
    if (isSample) {
      emit('on-send', hasFace.value);
    }
  });
};
function getPhoto(callback) {
  let canvas = document.getElementById("canvas");
  let context = canvas.getContext("2d");
  let video = document.getElementById("video");
  context.drawImage(video, 0, 0, 250, 250);
  if (callback) {
    tip.value = '';
    if (faceBlob.value.length) {
      const random = Math.floor(Math.random() * faceBlob.value.length);
      callback && callback(faceBlob.value[random]);
    } else {
      canvas.toBlob((blob) => {
        let fileList = new File([blob], new Date()+'.png',{type: 'image/png'});
        let formData = new FormData();
        formData.append("file", fileList);
        callback && callback(formData);
      });
    }
  } else {
    const base64 = canvas.toDataURL('image/jpeg');
    canvas.toBlob((blob) => {
      let fileList = new File([blob], new Date()+'.png',{type: 'image/png'});
      let formData = new FormData();
      formData.append("file", fileList);
      faceBlob.value.push(formData);
    });
    return base64.replace(/data:image\/jpeg;base64,/, '');
  }
};
async function handleTest() {
  tip.value = '';
  // 不自动活体检测
  if (!props.isTest) {
    const param = {
      blob: faceBlob.value,
      base64: faceImg.value,
    };
    return emit('on-send-face', param);
  }
  const res = await detectionFace({ fileBase: faceImg.value });
  if (res.ret === 0) {
    if (res.data === 1) {
      emit('on-send', true);
    } else {
      clearFace();
    }
  }
}
function clearFace() {
  faceImg.value = [];
  faceBlob.value = [];
  isSaveImg.value = true;
};

function stopCamera() {
  showAnimation.value = false;
  normalFace.value = true;
  clearFace();
  if (trackerTask) trackerTask.stop();
  if (mediaStreamTrack) mediaStreamTrack.srcObject.getTracks()[0].stop();
}

onUnmounted(() => {
  stopCamera();
});
defineExpose({
  openCamera,
  stopCamera,
  getPhoto,
  clearFace,
});

const emit = defineEmits(["emit"]);
</script>
<style lang="less" scoped>
.face-main {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    // border: 1px solid red;
    background-color: rgba(12, 207, 141, 0.1);
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      border-radius: 50%;
    }

    #video,
    #canvas {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 0;
      padding: 0;
      margin: 0;
      transform: rotateY(180deg);
    }
    #canvas {
      z-index: -1;
    }
    img {
      width: 105%;
      height: 105%;
    }
    .tip {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
    }
  }
  .fail {
    &::before {
      border: 2px solid red;
    }
  }
  .ok {
    &::before {
      border: 2px solid #2ad82a;
    }
  }
.pane-box {
  position: absolute;
  width: 280px;
  height: 280px;
  top: 0;
  left: 0;
  padding: 15px;
  z-index: 11;
}
.pane {
  position: absolute;
  width: 98%;
  height: 50%;
  top: 0;
  left: 1%;
  margin: 0 auto;
  // background: linear-gradient(to bottom, rgba(55, 125, 255, 0.4), rgba(55, 125, 255, 0.1), transparent);
  background: linear-gradient(to bottom, transparent, rgba(55, 125, 255, 0.1), rgba(55, 125, 255, 0.4));
  border-bottom: 1px solid #377dff;
  animation: move 4s ease-in-out infinite;
}
@keyframes move {
  from{ top: -50% }
  to{ top: 200% }
}
.normal-face {
  position: absolute;
  top: 4%;
  left: 4%;
  right: 4%;
  bottom: 4%;
  background: #fff url('~@/assets/image/normal-face.png') no-repeat;
  background-size: cover;
  border-radius: 50%;
  z-index: 12;
}
</style>
