<template>
  <div class="capture">
    <div class="capture-face">
      <div class="border border-1"></div>
      <div class="border border-2"></div>
      <div class="border border-3"></div>
      <div class="border border-4"></div>
      <!-- 人脸识别 -->
      <slot></slot>
    </div>
    <div class="capture-action">
      <slot name=action></slot>
    </div>
    <div class="capture-tip">
      <div class="face-tip">
        <!-- 请将您的<span>脸部</span>放入识别框内识别 -->
        {{ $t('course.face_tip', 0) }}
        <span>{{ $t('course.face_tip', 1) }}</span>
        {{ $t('course.face_tip', 2) }}
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
<style lang="less" scoped>
@border-w: 5px;
@face-pd: 16px;
.capture {
  width: 300px;
  margin: 0 auto;
}

.capture-face {
  position: relative;
  width: 280px;
  height: 280px;
  padding: @face-pd;
  overflow: hidden;

  .border {
    position: absolute;
    width: 35px;
    height: 35px;
    border-color: #377dff;
    border-style: solid;
  }

  .border-1 {
    top: 0;
    left: 0;
    border-top-width: @border-w;
    border-left-width: @border-w;
    border-top-left-radius: @border-w;
  }

  .border-2 {
    top: 0;
    right: 0;
    border-top-width: @border-w;
    border-right-width: @border-w;
    border-top-right-radius: @border-w;
  }

  .border-3 {
    bottom: 0;
    right: 0;
    border-bottom-width: @border-w;
    border-right-width: @border-w;
    border-bottom-right-radius: @border-w;
  }

  .border-4 {
    bottom: 0;
    left: 0;
    border-bottom-width: @border-w;
    border-left-width: @border-w;
    border-bottom-left-radius: @border-w;
  }

  .face-main {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    // border: 1px solid red;
    background-color: rgba(12, 207, 141, 0.1);
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      border-radius: 50%;
    }

    #video,
    #canvas {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 0;
      padding: 0;
      margin: 0;
      transform: rotateY(180deg);
    }
    img {
      width: 105%;
      height: 105%;
    }
  }
  .fail {
    &::before {
      border: 2px solid red;
    }
  }
  .ok {
    &::before {
      border: 2px solid #2ad82a;
    }
  }
}

.capture-action {
  margin: 40px 0 20px;
}

.capture-tip {
  .face-tip {
    text-align: center;
    color: #999;

    span {
      color: var(--theme);
    }
  }
}
</style>
