<template>
  <div class="comment-wrap">
    <div class="add-note">
      <a-button type="primary" @click="openNote">{{ $t('AddNotes') }}</a-button>
    </div>
    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="dataList"
    >
      <template #loadMore>
        <div
          :style="{
            textAlign: 'center',
            marginTop: '30px',
            height: '32px',
            lineHeight: '32px',
            color: '#BCBCBC',
          }"
        >
          <a-spin v-if="loading" />
          <div v-else-if="dataList.length <= 0"></div>
          <div v-else-if="finished">{{ $t('no_more') }}</div>
          <a-button v-else @click="loadMore">{{ $t('load_more') }}</a-button>
        </div>
      </template>
      <template #renderItem="{ item, index }">
        <a-list-item>
          <a-list-item-meta>
            <template #description>
              <div class="op">
                <div>
                  <a-tooltip :title="dateFormat(item.modifyTime || item.createTime, 'YYYY-MM-DD HH:mm:ss')">
                    {{ timeFrom(item.modifyTime || item.createTime) }}
                  </a-tooltip>
                </div>
                <a-space size="middle">
                  <a key="list-loadmore-delete" class="edit" @click="$refs.editNotesRef.show(item)">
                     <EditOutlined style="font-size: 20px" />
                   </a>
                   <a key="list-loadmore-delete" class="del">
                     <a-popconfirm
                       :title="$t('SureYouWantToDeleteTheNote')"
                       @confirm="del(item.noteId)"
                     >
                       <DeleteOutlined style="font-size: 20px" />
                     </a-popconfirm>
                   </a>
                </a-space>
              </div>
              <p
                class="content wangeditor-box"
                v-html="item.content"
              ></p>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <editNotes ref="editNotesRef" @success="reloadList()" />
  </div>
</template>

<script>
import { getCurrentInstance, reactive, ref, toRefs } from 'vue';
import { getCourseNotesList, delCourseNotes } from '@/api/course';
import { timeFrom, dateFormat } from "@/utils/tools";
import editNotes from './editNotes.vue';

export default {
  name: 'courseNotes',
  components: {
    editNotes,
  },
  props: {
    dataInfo: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      params: {
        courseID: props.dataInfo.courseId,
        page: 1,
        pageSize: 30,
      },
      loading: true, // 是否处于加载状态，加载过程中不触发load事件
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      dataList: [],
    });

    //上拉加载
    const loadMore = () => {
      state.loading = true;
      // 异步更新数据
      setTimeout(() => {
        getList();
      }, 100);
    };

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.params.page = 1;
      state.loading = true;
      state.finished = false;
      getList();
    };

    const getList = () => {
      getCourseNotesList(state.params).then((res) => {
        if (res.ret == 0) {
          state.dataList.push.apply(state.dataList, res.data.list);
          state.params.Total = res.data.totals;
          //每次拿到数据后页数加1
          state.params.page += 1;
          // 加载状态结束
          state.loading = false;
          // 数据全部加载完成
          if (state.dataList.length >= res.data.totals) {
            state.finished = true;
          }
        } else {
          state.loading = false;
          // proxy.$message.error(res.Message);
          return false;
        }
      });
    };

    const editNotesRef = ref(null);
    const openNote = () => {
      const data = {
        courseId: props.dataInfo.courseId,
        title: props.dataInfo.courseName,
        content: "",
        noteId: 0,
      };
      editNotesRef.value.show(data);
    };
    const del = (noteId) => {
      delCourseNotes({ noteid: noteId }).then((res) => {
        if (res.ret === 0) {
          proxy.$message.success(proxy.$t('CM_SuccessfullyDelete'));
          reloadList();
        } else {
          proxy.$message.error(res.msg);
        }
      });
    };

    setTimeout(() => {
      getList();
    }, 100);

    return {
      ...toRefs(state),
      timeFrom,
      dateFormat,
      loadMore,
      reloadList,
      del,
      editNotesRef,
      openNote,
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  // height: 60px;
  // overflow: hidden;
  padding: 0;
  margin: 0;
  color: #333;
  // cursor: pointer;
}
.op {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .edit {
    color: var(--theme);
  }
  .del {
    color: #ff3131;
    &:hover {
      color: #ff3131;
    }
  }
}
.add-note {
  margin-bottom: 10px;
  text-align: right;
}
</style>
